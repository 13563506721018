import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {Alerts} from '../../ui/Alerts/Alerts';
import {BlockHeader} from '../../ui/BlockHeader/BlockHeader';
import {ContactAddress} from '../contact.class';
import {contactInfoModalValidation} from './ContactInfoModal.validation';
import {ContactsService} from '../../../client/contacts/contacts.service';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {Input} from '../../ui/forms/Input/Input';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';

interface UpdateContactInfoForm {
    billToAddr1: string;
    billToAddr2: string;
    billToCity: string;
    billToEmail: string;
    billToName: string;
    billToPhone: string;
    billToState: string;
    billToZip: string;
    contactEmail: string;
    contactName: string;
    contactPhone: string;
    shipToAddr1: string;
    shipToAddr2: string;
    shipToCity: string;
    shipToEmail: string;
    shipToName: string;
    shipToPhone: string;
    shipToState: string;
    shipToZip: string;
}

interface ContactInfoModalProps {
    contactAddressArray: ContactAddress[];
    disableRemindMeLater?: boolean;
    onClose: () => void;
    onSuccess: () => void;
    show: boolean;
}

export const ContactInfoModal = ({contactAddressArray, disableRemindMeLater, onClose, onSuccess, show}: ContactInfoModalProps) => {
    const errorMessageRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(``);
    const contactsService: ContactsService = useService(`contactsService`);
    const formRef = useRef(null);
    const popupType = disableRemindMeLater ? `update` : `verify`;
    const useFormReturn = useForm({mode: `onBlur`, resolver: yupResolver(contactInfoModalValidation)});

    // Scroll error into view
    useEffect(() => {
        if (errorMessage && errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView();
        }
    }, [errorMessage]);

    /**
     * Converts
     * @param updateContactInfoForm
     */
    const getContactAddressArrayParam = (updateContactInfoForm: UpdateContactInfoForm): ContactAddress[] => {
        const contactAddressArrayParam: ContactAddress[] = [];

        // contactAddress
        contactAddressArrayParam.push({
            email: updateContactInfoForm.contactEmail,
            name: updateContactInfoForm.contactName,
            phoneNbr: updateContactInfoForm.contactPhone,
            type: `contact`,
        });

        // shipToAddress
        contactAddressArrayParam.push({
            addr1: updateContactInfoForm.shipToAddr1,
            addr2: updateContactInfoForm.shipToAddr2,
            city: updateContactInfoForm.shipToCity,
            email: updateContactInfoForm.shipToEmail,
            name: updateContactInfoForm.shipToName,
            phoneNbr: updateContactInfoForm.shipToPhone,
            st: updateContactInfoForm.shipToState,
            type: `shipto`,
            zip: updateContactInfoForm.shipToZip,
        });
        // billToAddress
        contactAddressArrayParam.push({
            addr1: updateContactInfoForm.billToAddr1,
            addr2: updateContactInfoForm.billToAddr2,
            city: updateContactInfoForm.billToCity,
            email: updateContactInfoForm.billToEmail,
            name: updateContactInfoForm.billToName,
            phoneNbr: updateContactInfoForm.billToPhone,
            st: updateContactInfoForm.billToState,
            type: `billto`,
            zip: updateContactInfoForm.billToZip,
        });

        // Return contactAddressArray
        return contactAddressArrayParam;
    };

    /**
     * Check to see if all form values are empty
     * @param updateContactInfoForm
     */
    const _allInputsEmpty = (updateContactInfoForm: UpdateContactInfoForm): boolean => {
        let allInputsEmpty = true;
        for (const index in updateContactInfoForm) {
            if (updateContactInfoForm.hasOwnProperty(index)) {
                if (updateContactInfoForm[index]) {
                    allInputsEmpty = false;
                    break; // Break loop
                }
            }
        }
        return allInputsEmpty;
    };

    /**
     * Loop through newAddresses and compare values to original contactAddressArray (relies on address types being in the same order)
     * @param newAddresses
     */
    const _allInputsSame = (newAddresses: ContactAddress[]): boolean => {
        let allInputsSame = true;
        newAddresses.forEach((address, index) => {
            for (const property in address) {
                if (address.hasOwnProperty(property)) {
                    if (address[property] !== contactAddressArray[index][property]) {
                        allInputsSame = false;
                        break; // Break loop
                    }
                }
            }
        });
        return allInputsSame;
    };

    /**
     * Resets the state of the modal before closing
     */
    const onCloseHandler = (formAbandoned?: boolean) => {
        if (formAbandoned) {
            gaLegacyCustomEvent({
                eventAction: `${popupType}_information`,
                eventCategory: `account_management`,
                eventLabel: `form_abandonment`,
            });

            // If user closed form without completion, remind them later if specified
            if (!disableRemindMeLater) {
                contactsService.remindContactInfo().catch(() => {
                    // Error silently
                });
            }
        }
        setErrorMessage(``);
        onClose();
    };

    /**
     * Sets error message and scrolls for display
     * @param errorMessageParam
     */
    const showError = (errorMessageParam: string) => {
        setErrorMessage(errorMessageParam);
    };

    /**
     * Submits form
     */
    const submitForm = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event(`submit`, {bubbles: true, cancelable: true}));
        }
    };

    /**
     * Submits no changes
     */
    const submitNoChanges = () => {
        contactsService
            .updateContactInfo()
            .then(() => {
                gaLegacyCustomEvent({
                    eventAction: `${popupType}_information`,
                    eventCategory: `account_management`,
                    eventLabel: `no_changes`,
                });
                onCloseHandler();
            })
            .catch(() => {
                // Error silently
                onCloseHandler();
            });
    };

    /**
     * Submits an update with provided contact info
     * @param updateContactInfoForm
     */
    const updateContactInfo = (updateContactInfoForm: UpdateContactInfoForm) => {
        setErrorMessage(``);

        // Show error if all inputs are empty
        if (_allInputsEmpty(updateContactInfoForm)) {
            showError(`There are no changes to submit. If everything looks correct, choose No Changes.`);
            return;
        }

        // Show error if all inputs are the same (despite being dirty)
        const updateArray = getContactAddressArrayParam(updateContactInfoForm);
        if (_allInputsSame(updateArray)) {
            showError(`There are no changes to submit. If everything looks correct, choose No Changes.`);
            return;
        }

        // Submit updateContactInfo
        contactsService
            .updateContactInfo(updateArray)
            .then(() => {
                gaLegacyCustomEvent({
                    eventAction: `${popupType}_information`,
                    eventCategory: `account_management`,
                    eventLabel: `save_changes`,
                });
                onCloseHandler();
                onSuccess();
            })
            .catch((updateContactInfoErr: ImpError) => {
                setErrorMessage(updateContactInfoErr.message);
            });
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <div className="tw-flex tw-flex-wrap tw-float-none tw-w-full lg:tw-w-auto lg:tw-float-right">
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={submitNoChanges}
                        size={`smToLg`}
                        variant="outline-secondary"
                    >
                        No Changes Needed
                    </Button>
                    <Button
                        className="lg:tw-mb-0 tw-ml-0"
                        disabled={!useFormReturn?.formState?.isDirty}
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={submitForm}
                        size={`smToLg`}
                        variant="secondary"
                    >
                        Save Changes
                    </Button>
                </div>
            }
            onClose={() => onCloseHandler(true)}
            show={show}
            title="Verify Information"
        >
            <>
                <p>Verify the following information is correct.</p>
                <Alerts
                    message={errorMessage}
                    ref={errorMessageRef}
                    variant="danger"
                />
                <FormProvider {...useFormReturn}>
                    <form
                        onSubmit={(event) => {
                            useFormReturn
                                .handleSubmit(updateContactInfo)(event)
                                .catch((formError) => {
                                    showError(formError.message);
                                });
                        }}
                        ref={formRef}
                    >
                        <div className="tw-flex tw-flex-wrap -tw-mx-2.5">
                            {contactAddressArray.map((contactAddress) => (
                                <React.Fragment key={contactAddress.type}>
                                    {contactAddress.type === `contact` && (
                                        <div className="tw-w-full tw-relative tw-px-2.5">
                                            <BlockHeader marginBottom={false}>Contact Information</BlockHeader>
                                            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border tw-rounded-sm tw-border-t-0">
                                                <div className="tw-p-4 tw-min-h-[1px] maskPII">
                                                    <Input
                                                        defaultValue={contactAddress.name}
                                                        error={useFormReturn?.formState?.errors?.contactName?.message as string}
                                                        label="Name"
                                                        name="contactName"
                                                        autocorrect="off"
                                                        {...useFormReturn.register(`contactName`)}
                                                    />
                                                    <Input
                                                        defaultValue={contactAddress.phoneNbr}
                                                        error={useFormReturn?.formState?.errors?.contactPhone?.message as string}
                                                        label="Phone #"
                                                        name="contactPhone"
                                                        {...useFormReturn.register(`contactPhone`)}
                                                    />
                                                    <Input
                                                        defaultValue={contactAddress.email}
                                                        error={useFormReturn?.formState?.errors?.contactEmail?.message as string}
                                                        label="E-Mail"
                                                        name="contactEmail"
                                                        autocorrect="off"
                                                        {...useFormReturn.register(`contactEmail`)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                            <div className="tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
                                {contactAddressArray.map((contactAddress) => (
                                    <React.Fragment key={contactAddress.type}>
                                        {contactAddress.type === `shipto` && (
                                            <div className="tw-w-full tw-px-2.5">
                                                <BlockHeader marginBottom={false}>Shipping Information</BlockHeader>
                                                <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border tw-rounded-sm tw-border-t-0">
                                                    <div className="tw-p-4 tw-min-h-[1px] maskPII">
                                                        <Input
                                                            defaultValue={contactAddress.name}
                                                            error={useFormReturn?.formState?.errors?.shipToName?.message as string}
                                                            label="Name"
                                                            name="shipToName"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`shipToName`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.addr1}
                                                            error={useFormReturn?.formState?.errors?.shipToAddr1?.message as string}
                                                            label="Shipping Address"
                                                            name="shipToAddr1"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`shipToAddr1`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.addr2}
                                                            error={useFormReturn?.formState?.errors?.shipToAddr2?.message as string}
                                                            label="Shipping Address 2"
                                                            name="shipToAddr2"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`shipToAddr2`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.city}
                                                            error={useFormReturn?.formState?.errors?.shipToCity?.message as string}
                                                            label="Shipping City"
                                                            name="shipToCity"
                                                            {...useFormReturn.register(`shipToCity`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.st}
                                                            error={useFormReturn?.formState?.errors?.shipToState?.message as string}
                                                            label="Shipping State"
                                                            name="shipToState"
                                                            {...useFormReturn.register(`shipToState`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.zip}
                                                            error={useFormReturn?.formState?.errors?.shipToZip?.message as string}
                                                            label="Shipping Postal Code"
                                                            name="shipToZip"
                                                            {...useFormReturn.register(`shipToZip`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.phoneNbr}
                                                            error={useFormReturn?.formState?.errors?.shipToPhone?.message as string}
                                                            label="Shipping Phone #"
                                                            name="shipToPhone"
                                                            {...useFormReturn.register(`shipToPhone`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.email}
                                                            error={useFormReturn?.formState?.errors?.shipToEmail?.message as string}
                                                            label="Shipping E-Mail"
                                                            name="shipToEmail"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`shipToEmail`)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}

                                {contactAddressArray.map((contactAddress) => (
                                    <React.Fragment key={contactAddress.type}>
                                        {contactAddress.type === `billto` && (
                                            <div className="tw-w-full tw-px-2.5">
                                                <BlockHeader marginBottom={false}>Billing Information</BlockHeader>
                                                <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border tw-rounded-sm tw-border-t-0">
                                                    <div className="tw-p-4 tw-min-h-[1px] maskPII">
                                                        <Input
                                                            defaultValue={contactAddress.name}
                                                            error={useFormReturn?.formState?.errors?.billToName?.message as string}
                                                            label="Billing Name"
                                                            name="billToName"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`billToName`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.addr1}
                                                            error={useFormReturn?.formState?.errors?.billToAddr1?.message as string}
                                                            label="Billing Street Address"
                                                            name="billToAddr1"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`billToAddr1`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.addr2}
                                                            error={useFormReturn?.formState?.errors?.billToAddr2?.message as string}
                                                            label="Billing Address 2"
                                                            name="billToAddr2"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`billToAddr2`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.city}
                                                            error={useFormReturn?.formState?.errors?.billToCity?.message as string}
                                                            label="Billing City"
                                                            name="billToCity"
                                                            {...useFormReturn.register(`billToCity`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.st}
                                                            error={useFormReturn?.formState?.errors?.billToState?.message as string}
                                                            label="Billing State"
                                                            name="billToState"
                                                            {...useFormReturn.register(`billToState`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.zip}
                                                            error={useFormReturn?.formState?.errors?.billToZip?.message as string}
                                                            label="Billing Postal Code"
                                                            name="billToZip"
                                                            {...useFormReturn.register(`billToZip`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.phoneNbr}
                                                            error={useFormReturn?.formState?.errors?.billToPhone?.message as string}
                                                            label="Billing Phone #"
                                                            name="billToPhone"
                                                            {...useFormReturn.register(`billToPhone`)}
                                                        />
                                                        <Input
                                                            defaultValue={contactAddress.email}
                                                            error={useFormReturn?.formState?.errors?.billToEmail?.message as string}
                                                            label="Billing E-Mail"
                                                            name="billToEmail"
                                                            autocorrect="off"
                                                            {...useFormReturn.register(`billToEmail`)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </>
        </ContentModal>
    );
};
